import React from 'react';
import { Img } from 'react-image';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { Icon } from '@components/common';

import CarrierLogoGeneric from '@components/modules/Imtu/components/CarrierLogoGeneric';

import CardItem from './index';

interface Props extends TestIdDataAttr {
  data: ImtuCarrierClass;
  isActive?: boolean;
  onClick: (e: React.MouseEvent, value: string) => void;
}

const ImtuCarrierCard: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, isActive, onClick } = props;

  const handleClick = (e: React.MouseEvent) => {
    onClick(e, data.code);
  };

  return (
    <CardItem
      leftPart={
        <Img
          className="w-[48px] h-[48px] p-middle"
          src={data.logoUrl}
          unloader={<CarrierLogoGeneric className="w-xlarge h-xlarge" />}
        />
      }
      middlePart={
        <div className="text-left text-body/primary/default">
          <div className="line-clamp-3">{data.name}</div>
        </div>
      }
      rightPart={
        <div className="flex justify-end h-full">
          <Icon
            className="text-color/black"
            width={16}
            height={16}
            name="chevron-forward-outline"
          />
        </div>
      }
      value={data.name}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export default ImtuCarrierCard;
