import axios from 'axios';
import { getTime, addMonths } from 'date-fns';

import BASE_PATHS from '@root/routes/paths';

import { callingBAxios } from '@services/axios';

import {
  AddAmountToBalanceRequest,
  AddAmountToBalanceResponse,
} from '@root/interfaces/contract/callingRechargePinless/AddAmountToBalance';
import { SetAutoRechargeRequest } from '@root/interfaces/contract/callingRechargePinless/SetAutoRecharge';
import { RechargeHistoryResponse } from '@root/interfaces/contract/callingRechargePinless/RechargeHistory';
import { ValidatePromoResponse } from '@root/interfaces/contract/callingRechargePinless/ValidatePromo';
import { AutoRechargePromoResponse } from '@root/interfaces/contract/callingRechargePinless/AutoRechargePromo';
import { CallingPaymentInfoResponse } from '@root/interfaces/contract/callingRechargePinless/CallingPaymentInfo';

import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';
import CallingRechargeReceiptClass from '@root/models/CallingRechargeReceiptClass';
import CallingPromoClass from '@root/models/CallingPromoClass';
import CallingRechargeTransactionClass from '@root/models/CallingRechargeTransactionClass';

import { CALLING_ENDPOINTS } from '@services/api/v1/constants/calling';
import { logError } from '@services/logging';
import { getSessionDataFromStorage } from '@services/auth';
import { CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

export const getCallingPaymentInfo = async () => {
  const res = await callingBAxios.get<CallingPaymentInfoResponse>(
    CALLING_ENDPOINTS.INIT_TOP_UP,
  );

  let arPromoRes;
  if (res.data.autorecharge_promo_content_url) {
    arPromoRes = await axios.get<AutoRechargePromoResponse>(
      res.data.autorecharge_promo_content_url,
    );
  }

  if (!res.data.credit_card_topup_default_amount) {
    logError({
      error: Error(
        'CALLING_ENDPOINTS.INIT_TOP_UP -> credit_card_topup_default_amount is not defined',
      ),
      errorInfo: res.data,
      userInfo: getSessionDataFromStorage(),
    });
  }

  return new CallingPaymentInfoClass(res.data, arPromoRes?.data);
};

export const setAutoRecharge = async (params: SetAutoRechargeRequest) => {
  await callingBAxios.post(CALLING_ENDPOINTS.SET_AUTO_RECHARGE, {
    amount: params.amount !== 0 ? params.amount * params.currencyDivisor : 0,
    currency_divisor: params.currencyDivisor,
    currency: params.currency,
  });
};

/**
 * Better use these promos on fresh account
 *
 * UNLIMITEDFEXP0, // Expired promo code: Code is good for 6/2/2017 only.
 * ONECGLOBALUNL,  // One time use per customer
 * FIXEDGLOBAL5,   // Global use of 5
 * UNLIMFIXEDNOTH, // Fixed with no threshold
 * UNLIMFIXEDTH5,  // Fixed with threshold (threshold should be $5)
 * UNLIMPERCNOTH,  // Percentage with no threshold (percentage should be 10%)
 * UNLIMPERCTH5,   // Percentage with threshold (percentage should be 10%, threshold should be $5)
 * ONECGLOBAL10    // One time use per customer and global use of 10
 *
 * @param amount
 * @param promoCode
 */
export const validatePromoCode = async (
  amount: number,
  promoCode: string,
  isAutoRechargePromo?: boolean,
) => {
  const divisor = 100;

  const res = await callingBAxios.post<ValidatePromoResponse>(
    CALLING_ENDPOINTS.VALIDATE_PROMO,
    {
      purchase_type: 'creditcard',
      promo_code: promoCode,
      topup_amount: amount * divisor,
    },
  );

  return new CallingPromoClass({
    data: res.data,
    promoCode,
    isAutoRechargePromo,
  });
};

export const addAmountToBalance = async (params: AddAmountToBalanceRequest) => {
  const res = await callingBAxios.post<AddAmountToBalanceResponse>(
    CALLING_ENDPOINTS.TOP_UP_CREDIT_CARD,
    {
      amount: params.amount * params.currencyDivisor,
      currency_divisor: params.currencyDivisor,
      currency: params.currency,
      promo_code: params.promoCode,
      handle_id: params.handleId,
      return_url_3ds: `${window.location.origin}${BASE_PATHS.CALLING}/${CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY}?${params.returnUrl3Ds}`,
      transaction_id_3ds: params.tnxId3Ds,
    },
  );

  if (res.data.status === 'pending_3ds') {
    return res.data;
  }

  return new CallingRechargeReceiptClass(res.data);
};

export const getRechargeHistory = async () => {
  const fromDate = getTime(addMonths(new Date(), -3));
  const toDate = getTime(new Date());

  const res = await callingBAxios.get<RechargeHistoryResponse>(
    `${CALLING_ENDPOINTS.RECHARGE_HISTORY}?from_date=${fromDate}&to_date=${toDate}`,
  );

  return res.data.entries.map((item) => new CallingRechargeTransactionClass(item));
};
