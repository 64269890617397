import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import BASE_PATHS from '@root/routes/paths';

import CallingPlansStore from '@components/modules/CallingPlans/CallingPlansStore';

import useDidMount from '@root/hooks/useDidMount';

import BrOrderStatus from '@components/common/BrOrderStatus';
import BrCard from '@components/common/BrCard';
import BrCallingPlanCompletedOrder from '@components/common/BrCallingPlanCompletedOrder';
import BrButton from '@components/common/BrButton';
import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';

import CancelSubSkeleton from './components/CancelSubSkeleton';

interface Props {
  planId: string;
  dataTestPrefix?: string;
}

const CallingPlanCancelSubSuccess: React.FC<Props> = (props) => {
  const { planId } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const callingPlansStore = useLocalStore(() => new CallingPlansStore());

  useDidMount(() => {
    callingPlansStore.fetchPlans();
  });

  const callingPlanTitle = callingPlansStore.getSelectedPlanById(planId)?.titleFull || '';

  const handleFinishClick = () => {
    history.push(BASE_PATHS.ACCOUNT);
  };

  const handleRedirectToCallingPlansClick = () => {
    history.push(BASE_PATHS.CALLING_PLANS);
  };

  return (
    <>
      {callingPlansStore.isLoading ? (
        <CancelSubSkeleton />
      ) : (
        <div className="flex flex-col space-y-default">
          <BrOrderStatus
            status="Canceled subscription"
            title={`${t('We`re sorry to see you go')} 🥹`}
          />

          <BrCard>
            <BrCallingPlanCompletedOrder
              products={[
                {
                  title: callingPlanTitle,
                },
              ]}
              descriptionText={`${t('Your subscription is cancelled')}.`}
            />
          </BrCard>

          <div className="flex flex-col space-y-small">
            <BrButton
              cmpType="text"
              hasIconLeft
              iconName="plans-outline"
              onClick={handleRedirectToCallingPlansClick}
              text={t('View other Calling Plans')}
            />
            <BrBottomControlsWrapper>
              <BrButton
                text={t("I'm done")}
                className="w-full"
                onClick={handleFinishClick}
              />
            </BrBottomControlsWrapper>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(CallingPlanCancelSubSuccess);
