/* eslint-disable @typescript-eslint/indent */
import { FC, PropsWithChildren } from 'react';

import './styles.scss';
import { normalizeStringCompound } from '@utils/string';

const BASIC_CLASS = 'br-card';

export interface BrCardProps {
  variant?:
    | 'default'
    | 'with-image-bg'
    | 'without-paddings'
    | 'featured'
    | 'without-bg'
    | 'no-bg-paddings'
    | 'with-liner';
  featuredText?: string;
  bgUrl?: string;
  className?: string;
}

const BrCard: FC<PropsWithChildren<BrCardProps>> = ({
  children,
  featuredText,
  bgUrl,
  variant = 'default',
  className,
}) => {
  const classNames = `${BASIC_CLASS} ${BASIC_CLASS}-${variant}`;
  const style = bgUrl ? { backgroundImage: `url(${bgUrl})` } : undefined;
  return (
    <div className={normalizeStringCompound([classNames, className])} style={style}>
      {featuredText && <span className="br-card--featured-label">{featuredText}</span>}
      {variant === 'with-liner' ? (
        <div className="br-card--linear-wrapper">{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default BrCard;
