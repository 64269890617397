import React from 'react';
import BrSkeleton from '@components/common/BrSkeleton';

const OfferSkeleton = () => {
  return (
    <BrSkeleton className="w-full mx-auto h-[92px] rounded-default  mb-xlarge mt-xsmall" />
  );
};

const OffersListSkeleton = () => {
  return (
    <>
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
    </>
  );
};

export default OffersListSkeleton;
