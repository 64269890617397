import { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useDidMount from '@root/hooks/useDidMount';

import BrReceipt from '@components/common/BrReceipt';
import PaymentCardStore from '@root/stores/PaymentCardStore';

import { stripAllNonNumericChars } from '@utils/string';

import CallingTxnDetailsLooperStore from '../CallingPlanOrderStatus/CallingTxnDetailsLooperStore';
import ReceiptHeader from './components/ReceiptHeader';
import ReceiptSkeleton from './components/ReceiptSkeleton';

import {
  getProductDetailsFromCallingPlanTxn,
  getPaymentAndRestDetailsFromCallingPlanTxn,
  getProductDetailsFromCallingPlanImtuTxn,
  getSubtotalDetailsFromCallingPlanTxn,
} from './helpers';

interface Props {
  txnId: string;
  dataTestPrefix?: string;
}

const CallingPlanReceipt: React.FC<Props> = (props) => {
  const { txnId } = props;

  const { t } = useTranslation();

  const callingPlanTnTxnDetailsLooperStore = useLocalStore(
    () => new CallingTxnDetailsLooperStore(),
  );

  const paymentCardStore = useLocalStore(() => new PaymentCardStore());

  useDidMount(() => {
    if (txnId) {
      callingPlanTnTxnDetailsLooperStore.updateTxnStatusInLoop(txnId);
    }
  });

  const { callingPlanTxn, imtuTxn } = callingPlanTnTxnDetailsLooperStore;

  useEffect(() => {
    if (callingPlanTxn?.callingPlanInfo.paymentCardHandleId) {
      paymentCardStore.getEditableCardByHandleId(
        callingPlanTxn?.callingPlanInfo.paymentCardHandleId,
      );
    }
  }, [callingPlanTxn?.callingPlanInfo.paymentCardHandleId]);

  return (
    <>
      {callingPlanTxn && paymentCardStore.editableCard?.maskedNumber ? (
        <div>
          <ReceiptHeader className="mb-xxxlarge" />
          <BrReceipt
            productTitle={callingPlanTxn.callingPlanInfo.title}
            total={callingPlanTxn.priceTotalText || t('Free')}
            imtuProductTitle={imtuTxn?.product?.name}
            productDetails={getProductDetailsFromCallingPlanTxn({
              callingPlanTxn,
              t,
            })}
            paymentDetails={getPaymentAndRestDetailsFromCallingPlanTxn({
              callingPlanTxn,
              paymentCard4digitNumber: stripAllNonNumericChars(
                paymentCardStore.editableCard.maskedNumber,
              ),
            })}
            imtuProductDetails={getProductDetailsFromCallingPlanImtuTxn({
              imtuTxn,
            })}
            subTotalDetails={getSubtotalDetailsFromCallingPlanTxn({
              callingPlanTxn,
              imtuTxn,
            })}
          />
        </div>
      ) : (
        <ReceiptSkeleton />
      )}
    </>
  );
};

export default observer(CallingPlanReceipt);
