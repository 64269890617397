import { paymentCardIssuersInfo } from '@root/constants/paymentCards/paymentCardTypes';
import { onlyDigitsRegexp } from '@root/constants/regexpPatterns';

// raw cardToken example: action=CE&data=1111111111111111
export const deserializeCardToken = (cardToken: string) => {
  const deserializedCardToken = cardToken
    .split('&') // [str, str]
    .map((el) => el.split('=')) // [[str, str], [str, str]]
    .reduce((accum: { [key: string]: string }, arr: string[]) => {
      const acc = { ...accum };
      acc[arr[0]] = arr[1].trim();
      return acc;
    }, {});
  return deserializedCardToken.data || undefined;
};

export const getPaymentCardInfo = (cardNumber: string) => {
  if (!onlyDigitsRegexp.test(cardNumber)) return undefined;

  return paymentCardIssuersInfo.find((cardTypeInfo) =>
    cardTypeInfo.pattern.test(cardNumber),
  );
};

// checksum algorithm created by Hans Peter Luhn
// https://en.wikipedia.org/wiki/Luhn_algorithm
export const luhnCheck = (cardNumber: string) => {
  let sum = 0;

  for (let i = 0; i < cardNumber.length; i += 1) {
    let cardNum = parseInt(cardNumber[i], 10);

    if ((cardNumber.length - i) % 2 === 0) {
      cardNum *= 2;

      if (cardNum > 9) {
        cardNum -= 9;
      }
    }

    sum += cardNum;
  }

  return sum % 10 === 0;
};

/**
 * Parse Date from 'MM/yy' format card expiration date string
 * @param dateString
 */
export const parseDateFromExpDateString = (dateString?: string | null) => {
  if (dateString) {
    const [expMonth = '', expYear = ''] = dateString.replaceAll(' ', '').split('/');

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = Number(String(new Date().getFullYear()).slice(2));

    if (expMonth.length !== 2 && expYear.length !== 2) {
      return null;
    }

    if (Number(expMonth) <= 0 || Number(expMonth) > 12) {
      return null;
    }

    if (expYear === '') {
      return null;
    }

    if (
      Number(expYear) > Number(String(currentYear).slice(2)) ||
      (Number(expYear) === currentYear && Number(expMonth) >= currentMonth)
    ) {
      return new Date(Number(`20${expYear}`), Number(expMonth), 0);
    }
  }

  return null;
};
