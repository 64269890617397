import { makeAutoObservable } from 'mobx';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import CountryCode from '@root/interfaces/CountryCode';

import { PromoV2 } from '@root/interfaces/contract/dtcImtuK2/PromosV2';

class ImtuPromoClass {
  id = 'undefined_imtu_promo_id';

  carrier = new ImtuCarrierClass();

  description = '';

  countryName = '';

  title = '';

  countryCode?: CountryCode;

  promoStartDate: Date | null = null;

  promoEndDate: Date | null = null;

  offerIds: string[];

  minValue?: {
    amount: string;
    currency: string;
  };

  maxValue?: {
    amount: string;
    currency: string;
  };

  constructor(data: PromoV2) {
    makeAutoObservable(this);

    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.carrier = new ImtuCarrierClass(data.carrier);
    this.countryName = data.country_name;
    this.countryCode = data.country;
    this.promoStartDate = new Date(data.start_at);
    this.promoEndDate = new Date(data.end_at);
    this.offerIds = data.offer_ids;
    this.minValue = data.min_value;
    this.maxValue = data.max_value;
  }
}

export default ImtuPromoClass;
