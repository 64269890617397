import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { useIsDesktop } from '../MediaQueryMatchers';

interface Props {
  className?: string;
  trackingNode?: HTMLElement;
}

const BrBottomControlsWrapper: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;

  const bottomControlsWrapperRef = useRef<HTMLDivElement>(null);

  const [placeholderHeight, setPlaceholderHeight] = useState<number | undefined>();

  const isDesktop = useIsDesktop();

  const placeholderRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setPlaceholderHeight(entries[0].contentRect.height);
    });

    if (bottomControlsWrapperRef.current) {
      observer.observe(bottomControlsWrapperRef.current);
    }

    return () => {
      if (bottomControlsWrapperRef.current) {
        observer.unobserve(bottomControlsWrapperRef.current);
      }
    };
  }, [isDesktop]);

  useEffect(() => {
    if (placeholderRef.current) {
      placeholderRef.current.style.height = !isDesktop ? `${placeholderHeight}px` : '';
    }
  }, [placeholderHeight]);

  return (
    <>
      {!isDesktop && <div ref={placeholderRef} id="controlsPlaceholder" />}
      <div
        ref={bottomControlsWrapperRef}
        className={normalizeStringCompound([
          'fixed bottom-0 left-0 right-0 lg:static bg-color/background lg:bg-initial rounded-t-default md:rounded-t-default lg:rounded-none z-30',
          className,
        ])}
      >
        <div className="br-container pt-middle pb-middle lg:pt-0 lg:pb-0 lg:max-w-[initial] lg:px-0">
          {children}
        </div>
      </div>
    </>
  );
};

export default BrBottomControlsWrapper;
