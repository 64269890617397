import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_ERROR_MSG } from '@root/constants/errorMessages';

import { getSessionDataFromStorage, handleExpiredSession } from '@services/auth';
import { logError } from '@services/logging';

import Axios from './Axios';
import {
  getCallingApiErrorMessage,
  getLocalStorageSession,
  handleHttpError,
  repeatCall,
} from './helpers';

const getRejectInterceptor = (axiosInstance: AxiosInstance) => {
  return (error: AxiosError<AxiosResponse>) => {
    logError({
      error,
      errorInfo: error?.response || error,
      userInfo: getSessionDataFromStorage(),
    });

    // http errors handling
    if (error.response) {
      return handleHttpError(error.response.status, {
        401: async () => {
          try {
            return await handleExpiredSession({
              axiosInstance,
              error,
            });
          } catch (err) {
            return Promise.reject(Error(API_ERROR_MSG.APP_DEFAULT));
          }
        },
        418: () => {
          return Promise.reject(
            getCallingApiErrorMessage(String(error.response?.data?.status)) ||
              API_ERROR_MSG.APP_DEFAULT,
          );
        },
      });
    }

    // repeat call on network error
    return repeatCall(axiosInstance, error.config);
  };
};

class CallingApi extends Axios {
  isRefresh = false;

  // going to be a duplicated code for a while - didn't want to extend
  constructor(config: AxiosRequestConfig) {
    super(config);

    this.instance.interceptors.request.use((axiosConfig) => {
      if (axiosConfig.headers) {
        // eslint-disable-next-line no-param-reassign, prettier/prettier
        axiosConfig.headers.Authorization = `Bearer ${getLocalStorageSession()?.accessToken}`;
      }
      return axiosConfig;
    });

    this.instance.interceptors.response.use(
      undefined,
      getRejectInterceptor(this.instance),
    );
  }
}

export default CallingApi;
