import React from 'react';
import { Img } from 'react-image';
import { useTranslation } from 'react-i18next';

import ImtuPromoClass from '@root/models/ImtuPromoClass';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { Flag } from '@components/common';
import BrButton from '@components/common/BrButton';

import CarrierLogoGeneric from '@components/modules/Imtu/components/CarrierLogoGeneric';

import CardItem from './index';

interface Props extends TestIdDataAttr {
  data: ImtuPromoClass;
  isActive?: boolean;
  onClick: (e: React.MouseEvent, value: string) => void;
  onDetailsClick?(e: React.MouseEvent, value: string): void;
}

const ImtuPromoCard: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, isActive, onClick, onDetailsClick } = props;

  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent) => {
    onClick(e, data.id);
  };

  const handleDetailsClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    onDetailsClick?.(e, data.id);
  };

  return (
    <CardItem
      leftPart={
        <Img
          className="w-[44px] h-[44px] p-middle"
          src={data.carrier.logoUrl}
          unloader={<CarrierLogoGeneric className="w-large h-large" />}
        />
      }
      middlePart={<div className="text-body/caption/medium text-left">{data.title}</div>}
      rightPart={
        <div className="flex flex-col h-full justify-center space-y-middle items-end">
          <Flag
            code={data.countryCode}
            className="w-large h-large border-0 rounded-full overflow-hidden"
          />
          <BrButton cmpType="link" size="xSmall" onClick={handleDetailsClick}>
            <span className="group-focus-visible:focus-ring">{t('Details')}</span>
          </BrButton>
        </div>
      }
      value={data.name}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export default ImtuPromoCard;
