import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuPromoClass from '@root/models/ImtuPromoClass';
import ImtuRecentContactClass from '@root/models/ImtuRecentContactClass';
import ImtuTransaction from '@root/models/ImtuTransaction';

import { fetchImtuConfig, fetchImtuPromosFromCarriers } from '@services/api/v1/dtcImtuK2';
import { showToast } from '@services/toasts';

class ImtuConfigStore {
  availableCountries: CountryCode[] = [];

  isLoading = false;

  promos: ImtuPromoClass[] = [];

  recentContacts: ImtuRecentContactClass[] = [];

  recentImtuTxns: ImtuTransaction[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchConfig = async () => {
    this.isLoading = true;

    try {
      const [imtuConfigRes, imtuPromosRes] = await Promise.all([
        fetchImtuConfig(),
        fetchImtuPromosFromCarriers(),
      ]);
      this.availableCountries = imtuConfigRes.country_codes;
      // TODO: to be removed when we switch to v2 promos - See ImtuPromosV2Store
      this.promos = imtuPromosRes.all_promos.map((item) => {
        return new ImtuPromoClass(item);
      });
      this.recentContacts = imtuConfigRes.recent_contacts.map((item) => {
        return new ImtuRecentContactClass({
          phoneNumber: item.msisdn,
          countryCode: item.country_code,
          carrierCode: item.carrier_code,
          promoIds: item.available_carrier_promo_ids || [],
          timestamp: item.timestamp,
        });
      });
      this.recentImtuTxns = imtuConfigRes.recent_topups.map((item) => {
        return new ImtuTransaction(item);
      });
    } catch (err) {
      showToast.error(err);
    } finally {
      this.isLoading = false;
    }
  };
}

export default ImtuConfigStore;
