import React, { useState } from 'react';

import MenuItem, { MenuItemProps } from '@components/common/BrMenu/MenuItem';

interface Props {
  data: MenuItemProps[];
}

const Menu: React.FC<Props> = ({ data }) => {
  const [itemOpenState, setItemOpenState] = useState<Record<string, boolean>>({
    ...Object.fromEntries(data.map((item) => [item.id, Boolean(item.isExpanded)])),
  });

  const toggleItemOpen = (id: string) => {
    setItemOpenState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <ul>
      {data.map((item) => {
        const isOpen = itemOpenState[item.id];

        const handleItemClick = () => {
          if (item.items) {
            toggleItemOpen(item.id);
          } else if (item.onClick) {
            item.onClick();
          }
        };

        return (
          <li key={item.id}>
            <MenuItem {...item} isExpanded={isOpen} onClick={handleItemClick} />
            {item.items && isOpen && <Menu data={item.items} />}
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
