import React, { FC } from 'react';

import { t } from 'i18next';

import { Typography } from '@components/common';
import BrBadge from '@components/common/BrBadge';

import { normalizeStringCompound } from '@utils/string';

import { NavigationListProps } from '../../types';

import './styles.scss';

const MenuList: FC<React.PropsWithChildren<NavigationListProps>> = (props) => {
  const { onItemSelect, className, dataTestPrefix, links } = props;

  const componentClassName = normalizeStringCompound(['text-center', className]);

  const linkItemClassName =
    'flex w-full px-5 text-center hover:text-gray-400 place-items-center h-20';

  const linkTextClassName =
    'text-2xl w-full flex items-center justify-center h-full font-medium';

  return (
    <ul className={componentClassName}>
      {/* TODO: put in separate list component */}
      {links.map((link) => {
        const handleItemClick = () => {
          if (onItemSelect) {
            onItemSelect();
          }
          if (link.onClick) {
            link.onClick();
          }
        };
        return (
          <li
            key={link.id}
            className="flex-grow not-last-child:border-b not-last-child:border-gray-300"
          >
            {link.path ? (
              <Typography.Link
                to={link.path}
                isNative={link.isNative}
                onClick={handleItemClick}
                className={linkItemClassName}
                activeClassName="text-gray-400"
                dataTestId={`${dataTestPrefix}-${link.id}`}
              >
                <span className={linkTextClassName}>
                  {link.name}
                  {link.isNew && (
                    <BrBadge
                      classType="black"
                      styleType="less-contrast"
                      className="ml-small"
                      text={`${t('New')} 🎉`}
                    />
                  )}
                </span>
              </Typography.Link>
            ) : (
              <button
                onClick={handleItemClick}
                className={linkItemClassName}
                data-test-id={`${dataTestPrefix}-${link.id}`}
              >
                <span className={linkTextClassName}>{link.name}</span>
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default MenuList;
