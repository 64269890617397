import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

class Store {
  isDetailsModalVisible = false;

  selectedCountryCode?: CountryCode;

  selectedPlanId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedCountryCode(countryCode: CountryCode) {
    this.selectedCountryCode = countryCode;
  }

  setSelectedPlanId(planId: string) {
    this.selectedPlanId = planId;
  }

  showDetailsModal() {
    this.isDetailsModalVisible = true;
  }

  closeDetailsModal() {
    this.isDetailsModalVisible = false;
  }
}

export default Store;
