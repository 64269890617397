import {
  AnalyticsAction,
  BackendFailureCategory,
  BackendFailureCallName,
} from '@services/analytics/constants';

import { ImtuProductType } from '@root/interfaces/MobileTopUp';

import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';

import CountryCode from './CountryCode';
import { BrazeCampaignDeliveryMethod, DeliveryMethod } from './DeliveryMethod';
import { PaymentMethods } from './paymentMethods/PaymentMethods';

type TrackingEventValueType = undefined | boolean | string | number | object;

export type TrackingEventParams = Dictionary<TrackingEventValueType>;

export enum AnalyticsContext {
  Login = 'login',
  MoneyTransfer = 'mt_flow',
  Imtu = 'imtu_flow',
  PromoCode = 'promo_code',
}

export enum AnalyticsScreen {
  SendPhoneNumber = 'send_phone_number',
  ConfirmSmsCode = 'confirm_sms_code',
  SendEmailLink = 'send_email_link',
  EmailSent = 'email_sent',
  AltLoginLinks = 'alt_login_links',
  DeliveryMethods = 'delivery_methods',
}

export interface ButtonPressEventParams {
  button_name?: string;
  context?: AnalyticsContext;
  index?: number;
  modal?: string;
  screen?: AnalyticsScreen;
  screen_class_name?: string;
  [key: string]: string | undefined | number;
}

export interface SelectEventParams {
  selectorName?: string;
  itemName?: string;
  index?: number;
}

export interface DeliveryMethodParams {
  method?: BrazeCampaignDeliveryMethod;
  amount: string;
  country: CountryCode;
  currency: string;
}

export interface MtPurchaseParams {
  payerId: string;
  subPayerId: string;
  pocId: string;
  deliveryMethod?: DeliveryMethod;
  recipientCountry: string | undefined;
  recipientCurrency: string;
  localAmount?: number;
  recipientPhone: string;
  recipientFirstName: string;
  recipientLastName: string;
}

export interface ImtuPurchaseTrackingParams {
  type: ImtuProductType;
  country?: CountryCode;
  phone: string;
  carrier: string; // Carrier Code
  plan: string; // Product Code
}

export interface AnalyticsErrorEventParams {
  category?: string;
  code?: string;
  detail?: string;
  message?: string;
  name?: string;
  screen?: string;
  subCategory?: string;
  userMessage?: string;
  data?: Dictionary<string>;
}

export interface ActionParams {
  name: AnalyticsAction | string;
  context: AnalyticsContext;
}

export interface PurchaseMtOptions {
  fee?: number;
  paymentMethod?: PaymentMethods;
  promoCode?: string;
  localAmount?: number;
  cancellationReasonCode?: string;
  cancellationReasonText?: string;
}

export interface PurchaseImtuOptions {
  paymentType: PaymentMethods;
}

export interface MtPurchaseItem {
  id: string;
  name: string;
  params: MtPurchaseParams;
}

export interface ImtuPurchaseItem {
  id: string;
  name: string;
  params: ImtuPurchaseTrackingParams;
}

export interface SentMtTransactionParams {
  transactionCpasId: string;
  amount: number;
  fee: number;
  userCountryCode: CountryCode;
  paymentMethod: PaymentMethods;
  promoCode?: string;
  purchaseParams: MtPurchaseParams;
}

export interface ImtuTxnTrackingParams {
  transactionId: string;
  amount: number;
  paymentType: PaymentCardTypeShort;
  userCountryCode: CountryCode;
  purchaseParams: ImtuPurchaseTrackingParams;
}

export interface CallingTxnTrackingParams {
  userCountryCode: CountryCode;
  price: number;
  currency: string;
  transactionId: string;
  planId: string;
  shouldSubscribe: boolean;
  planTitle: string;
  planDescription: string;
}

export interface SentCallingRechargeTransactionParams {
  amount: number;
  isAutoRechargeEnabled: boolean;
  cardHandleId: string;
  userCountryCode: CountryCode;
  promoCode?: string;
}

export interface ImtuPurchaseMarketingServiceParams {
  transactionId: string;
  amount: number;
  item: ImtuPurchaseItem;
}

export interface BackendFailureParams {
  callName?: BackendFailureCallName;
  category?: BackendFailureCategory;
  code?: string;
  message?: string;
  data?: Dictionary<string>;
}

export interface TransactionFailureData {
  uuid: string;
  CPASID: string;
  STSCSenderID: string;
  TransactionID: string;
}
