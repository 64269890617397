import SimpleBar from 'simplebar-react';

import { MutableRefObject, useLayoutEffect, useState } from 'react';

interface Props {
  maxHeight: number;
  listRef: MutableRefObject<MutableRefObject<SimpleBar> | null>;
  deps: unknown[];
}
const useDynamicHeight = (props: Props) => {
  const { maxHeight, listRef, deps } = props;
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    // there is a lib's related type issue (property children doesn't exist on type Simplebar)
    // @ts-ignore
    const listOffsetHeight = listRef.current?.current?.children[0]?.offsetHeight;
    setHeight(Math.min(listOffsetHeight ?? maxHeight, maxHeight));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { dynamicHeightValue: height };
};

export default useDynamicHeight;
