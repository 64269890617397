/* eslint-disable @typescript-eslint/indent */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  useLayoutEffect,
} from 'react';

import { normalizeStringCompound, replaceAccentCharacters } from '@utils/string';

import useNavigation from '@root/hooks/useNavigation';
import useOnClickOutside from '@root/hooks/useOnClickOutside';

import delay from '@utils/delay';

import { OptionProps } from '@root/interfaces/components/Select';
import BrSelectProps from '@root/interfaces/components/BrSelect';

import BrInput from '../BrInput';

import { useIsDesktop } from '../MediaQueryMatchers';

import Dropdown from './Dropdown';
import Drawer from './Drawer';

import './styles.scss';

export type SelectRef = {
  setSelectIsOpen(val: boolean): void;
  isOpen: boolean;
  drawerRef: React.RefObject<HTMLDivElement>;
};

export type SelectForwardRef = ForwardedRef<SelectRef>;

const BR_INPUT_ICON_RIGHT_CLASS_NAME = '.br-input-icon-right';
const DELAY_BEFORE_DROPDOWN_WILL_BE_CLOSED_MS = 150;

const BrSelect = forwardRef((props: BrSelectProps, ref: SelectForwardRef) => {
  const {
    value,
    placeholder,
    drawerTitleText,
    options,
    selectButtonAddonBefore,
    dataTestId,
    notFoundText = 'Sorry, no matches found', // t('Sorry, no matches found')
    isOpened,
    isDisabled,
    isAllowUnselect = true,
    hasSearch,
    onChange,
    onClose,
    onFocus,
    onOpen,
    children,
    renderItem,
    dropdownAddonBottom,
    drawerAddonBottom,
    dropdownMaxHeight,
    ignoredElemsRefs,
    onAnimationEnd,
    dropdownOptionHeight,
    className,
    dropdownClassName,
    ...rest
  } = props;

  const valueOption =
    typeof value === 'string' ? options.find((item) => item.value === value) : value;

  const [currentOption, setCurrentOption] = useState(valueOption);
  const [isOpen, setIsOpen] = useState(false);
  const [isPrevOpened, setIsPrevOpened] = useState<boolean | undefined>(false);
  const [searchValue, setSearchValue] = useState('');

  const isClosing = useRef(false);

  const mobileInputRef = useRef<HTMLInputElement>(null);

  if (isPrevOpened !== isOpened) {
    setIsPrevOpened(isOpened);
    setIsOpen(Boolean(isOpened));
  }

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputIconRightRef = useRef<HTMLDivElement | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isDesktopResolution = useIsDesktop();

  const optionsToRender = useMemo(() => {
    return options.reduce((acc: OptionProps[], item) => {
      const normalizedSourceString = replaceAccentCharacters(item.text.toLowerCase());
      const normalizedQueryString = replaceAccentCharacters(searchValue.toLowerCase());
      const shouldIncludeItem = normalizedSourceString.includes(normalizedQueryString);
      if (shouldIncludeItem) {
        return currentOption?.value === item.value && !isClosing.current
          ? [item, ...acc]
          : [...acc, item];
      }

      return acc;
    }, []);
  }, [options, searchValue, isOpen, isClosing.current]);

  const handleClickOutside = async () => {
    if (!optionsToRender.length) {
      setSearchValue('');
    }
    setIsOpen(false);
    inputRef.current?.blur();
  };

  const ignoredElemRefs = [
    dropdownRef,
    drawerRef,
    inputRef,
    inputIconRightRef,
    mobileInputRef,
    ...(ignoredElemsRefs ?? []),
  ];

  useOnClickOutside(handleClickOutside, ...ignoredElemRefs);

  useEffect(() => {
    const iconRight = document.querySelector<HTMLDivElement>(
      BR_INPUT_ICON_RIGHT_CLASS_NAME,
    );
    inputIconRightRef.current = iconRight;
  }, []);

  const getExposedSelectApi = () => ({
    setSelectIsOpen(val: boolean) {
      setIsOpen(val);
    },
    isOpen,
    drawerRef,
  });

  useImperativeHandle(ref, getExposedSelectApi);

  // update selected value when the value or the options have changed
  useEffect(() => {
    setCurrentOption(valueOption);
  }, [value, options, valueOption]);

  useLayoutEffect(() => {
    if (isOpen && !isDesktopResolution) {
      inputRef.current?.blur();
    }
  }, [isOpen, isDesktopResolution]);

  useEffect(() => {
    if (isOpen && isDesktopResolution) {
      inputRef.current?.focus();
    }
  }, [isDesktopResolution, isOpen]);

  useEffect(() => {
    if (isDesktopResolution) {
      return undefined;
    }
    if (isOpen) {
      document.documentElement.classList.add('overflow-hidden');
    } else {
      document.documentElement.classList.remove('overflow-hidden');
    }
    return () => {
      document.documentElement.classList.remove('overflow-hidden');
    };
  }, [isOpen, isDesktopResolution]);

  const handleSelectFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFocus?.(e);
  };

  const handleSelectClick = () => {
    setIsOpen((s) => !s);
    if (isDesktopResolution) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  };

  const handleOptionSelect = async (option: OptionProps) => {
    const isSelectedOption = option.value === currentOption?.value;
    if (isSelectedOption && !isAllowUnselect) {
      return undefined;
    }
    setCurrentOption(isSelectedOption && isAllowUnselect ? undefined : option);
    await delay(DELAY_BEFORE_DROPDOWN_WILL_BE_CLOSED_MS);
    isClosing.current = true;
    setIsOpen(false);
    return undefined;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const inputClassNames = normalizeStringCompound([
    isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
    'focus-within:border-blue-400 truncate',
  ]);

  useNavigation(dropdownRef, 'button.block', [optionsToRender]);

  const handleInputRef = (compRef: HTMLInputElement | null) => {
    if (compRef) {
      inputRef.current = compRef;
    }
  };

  const handleOnClearIconClick = () => {
    if (searchValue) {
      setSearchValue('');
    }
  };

  const handleAnimationEnd = () => {
    if (isOpen) {
      onOpen?.();
    } else {
      isClosing.current = false;
      onChange?.(currentOption?.value);
      onClose?.();
      setSearchValue('');
    }
    if (!isDesktopResolution) {
      inputRef.current?.blur();
    }
    onAnimationEnd?.();
  };

  const mobileSearchInput = (
    <BrInput
      onChange={hasSearch ? handleSearch : undefined}
      placeholder={placeholder}
      value={searchValue}
      autoComplete="nope"
      onWhat="surface"
      ref={mobileInputRef}
      iconRight={searchValue ? 'CloseXSolid' : undefined}
      onIconClickRight={handleOnClearIconClick}
    />
  );

  const brSelectClassNames = normalizeStringCompound([
    'br-select',
    isOpen ? 'br-select-opened' : '',
    !searchValue || !isOpen ? 'br-select_chevron-icon' : 'br-select_erase-icon',
    className,
  ]);

  const handleBrDrawerRef = (
    brDrawerRef: {
      drawerRef: React.RefObject<HTMLDivElement>;
    } | null,
  ) => {
    if (brDrawerRef) {
      drawerRef.current = brDrawerRef.drawerRef.current;
    }
  };

  const handleBrDrawerOnClose = () => {
    setIsOpen(false);
  };

  const renderCustomListItem = renderItem
    ? (
        item: {
          option: OptionProps;
          isActive: boolean;
          onSelect: (options: OptionProps) => void;
        },
        index: number,
      ) => {
        return renderItem(item, index);
      }
    : undefined;

  return (
    <div className={brSelectClassNames}>
      {typeof children === 'function' ? (
        children(Boolean(isOpen), handleSelectClick)
      ) : (
        <BrInput
          addonBefore={selectButtonAddonBefore}
          isReadOnly={!hasSearch || !isDesktopResolution}
          value={isOpen && hasSearch ? searchValue : currentOption?.text || ''}
          className={inputClassNames}
          isDisabled={isDisabled}
          onFocus={handleSelectFocus}
          onClick={handleSelectClick}
          onChange={hasSearch ? handleSearch : undefined}
          ref={handleInputRef}
          dataTestId={`${dataTestId}-input`}
          autoComplete="nope"
          placeholder={placeholder}
          iconRight={searchValue && isOpen ? 'CloseXSolid' : 'chevron-down-outline'}
          iconRightTabIndex={searchValue && isOpen ? 0 : -1}
          onIconClickRight={handleOnClearIconClick}
          {...rest}
        />
      )}
      {isDesktopResolution ? (
        <Dropdown
          addonBottom={dropdownAddonBottom}
          ref={dropdownRef}
          options={optionsToRender}
          notFoundText={notFoundText}
          selectedOption={currentOption}
          renderItem={renderItem}
          searchValue={searchValue}
          isOpen={isOpen}
          onAnimationEnd={handleAnimationEnd}
          onSelect={handleOptionSelect}
          dropdownMaxHeight={dropdownMaxHeight}
          optionHeight={dropdownOptionHeight}
          className={dropdownClassName}
        />
      ) : (
        <Drawer
          title={drawerTitleText}
          options={optionsToRender}
          notFoundText={notFoundText}
          selectedOption={currentOption}
          searchValue={searchValue}
          renderItem={renderCustomListItem}
          renderMobileSearchInput={
            hasSearch && <div className="mb-small">{mobileSearchInput}</div>
          }
          isOpen={!isDesktopResolution && isOpen}
          ref={handleBrDrawerRef}
          onClose={handleBrDrawerOnClose}
          onAnimationEnd={handleAnimationEnd}
          onSelect={handleOptionSelect}
          addonBottom={drawerAddonBottom}
        />
      )}
    </div>
  );
});

export default BrSelect;
