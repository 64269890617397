import { reaction, makeAutoObservable } from 'mobx';
import i18n from '@services/i18n';
import { getUnixTime } from 'date-fns';

import {
  fetchUserProfile,
  updateUserProfile,
  uploadProfileAvatar,
} from '@services/api/v1/userAccount';
import * as analyticsService from '@services/analytics';
import * as notificationsService from '@services/notifications';
import {
  deleteUserAccountAndLogout,
  getIsLoggedInStatusFromStorage,
  getSessionDataFromStorage,
} from '@services/auth';
import { showToast } from '@services/toasts';
import { getGeoposition } from '@services/geoposition';
import { deleteCookie, setCookie } from '@services/cookies';
import { logError } from '@services/logging';

import UserAccountClass from '@root/models/UserAccountClass';
import UserFinanceProfileClass from '@root/models/UserFinanceProfileClass';
import GeolocationClass from '@root/models/GeolocationClass';

import Geoposition from '@root/interfaces/Geoposition';
import { LanguageLocale, Languages } from '@root/interfaces/Languages';
import CountryCode from '@root/interfaces/CountryCode';

import { GEOLOCATION_ERROR_MSG } from '@root/constants/errorMessages';
import { USER_SESSION } from '@root/constants/cookies';
import HARDCODED_VARS from '@root/constants/hardcodedDevVariables';

import { getLanguageCodeByAvailable } from '@helpers/language';
import { getCountryCodeByPhoneNumber } from '@helpers/phone';

import { getTopLevelDomain } from '@utils/url';

class UserAccountStore {
  profile: UserAccountClass;

  financeProfile?: UserFinanceProfileClass | null;

  constructor() {
    makeAutoObservable(this);

    this.profile = new UserAccountClass({
      language: getLanguageCodeByAvailable(i18n.language),
    });

    reaction(
      () => [this.profile, this.currentLanguage],
      () => {
        analyticsService.setupUser({
          ...this.profile,
          language: this.currentLanguage,
        } as UserAccountClass);
        notificationsService.setupWebPushNotifications();
        notificationsService.setupInAppNotifications();
        // analyticsService.trackAskForWebPush();
      },
    );

    reaction(
      () => this.profile.language,
      (language) => {
        if (language) {
          i18n.changeLanguage(language);
        }
      },
    );
  }

  isUserLoggedIn = false;

  isLoading = false;

  isLoaded = false;

  geoposition?: Geoposition; // TODO: should be removed because we have separate GeolocationStore

  geolocation: GeolocationClass | null = null; // TODO: should be removed because we have separate GeolocationStore

  getSession = () => {
    return getSessionDataFromStorage();
  };

  updateLoggedInStatus = async () => {
    const session = this.getSession();
    const isUserLoggedIn = getIsLoggedInStatusFromStorage();

    const authCookieOptions = {
      domain: getTopLevelDomain(window.location),
    };

    if (isUserLoggedIn) {
      await this.getDtcAndFinanceProfiles();
      // cookies needed for bossrevolution.com
      setCookie(
        USER_SESSION.COOKIE_KEY,
        `Bearer ${session?.accessToken}`,
        authCookieOptions,
      );
    } else {
      deleteCookie(USER_SESSION.COOKIE_KEY, authCookieOptions);
    }

    this.isUserLoggedIn = isUserLoggedIn;

    return {
      isUserLoggedIn,
    };
  };

  getDtcAndFinanceProfiles = async () => {
    this.setIsLoading(true);
    try {
      // if there is unset changes that were made on the login screen
      if (this.profile.language) {
        this.profile = await updateUserProfile({ language: this.profile.language });
      } else {
        this.profile = await fetchUserProfile();
      }
    } catch (err) {
      showToast.error(err.message);
      throw err;
    } finally {
      this.setIsLoading(false);
      this.setIsLoaded(true);
    }
  };

  changeLanguage = async (lng: Languages) => {
    if (Object.values(Languages).includes(lng)) {
      this.profile.language = lng;
      // forcing language changing, reaction sometimes doesn't work
      i18n.changeLanguage(lng);
      if (this.isUserLoggedIn) {
        this.setIsLoading(true);
        try {
          await updateUserProfile({ language: lng });
        } catch (err) {
          showToast.error(err.message);
        } finally {
          this.setIsLoading(false);
        }
      }
    }
  };

  getProfile = async () => {
    this.setIsLoading(true);
    try {
      this.profile = await fetchUserProfile();
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.setIsLoading(false);
    }
    return this.profile;
  };

  saveUserName = async (params: { firstName: string; lastName: string }) => {
    this.setIsLoading(true);
    try {
      this.profile = await updateUserProfile(params);
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.setIsLoading(false);
    }
  };

  saveDateOfBirth = async (dob: Date) => {
    this.setIsLoading(true);
    try {
      this.profile = await updateUserProfile({
        dob: getUnixTime(dob),
      });
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.setIsLoading(false);
    }
  };

  saveAvatar = async (file: File) => {
    this.setIsLoading(true);
    try {
      const avatarUrl = await uploadProfileAvatar(file);
      this.profile = await updateUserProfile({
        avatarUrl,
      });
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.setIsLoading(false);
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setIsLoaded = (value: boolean) => {
    this.isLoaded = value;
  };

  // TODO: should be removed because we have separate GeolocationStore
  refreshGeoposition = async () => {
    try {
      const geoResponse = await getGeoposition();
      this.geoposition = {
        lat: geoResponse.coords.latitude,
        lng: geoResponse.coords.longitude,
      };
    } catch (err) {
      switch (true) {
        case err === GEOLOCATION_ERROR_MSG.PERMISSION_DENIED:
          showToast.error(err.message);
          break;
        default:
          await this.refreshGeoposition();
      }
    }
  };

  deleteUserAccountAndLogout = async () => {
    try {
      await deleteUserAccountAndLogout(this.profile.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  get userCountryOfOrigin() {
    let countryOfOrigin;
    if (this.financeProfile?.address?.countryCode) {
      countryOfOrigin = this.financeProfile?.address?.countryCode;
    } else if (this.profile.phone) {
      const countryCodeParsedFromPhone = getCountryCodeByPhoneNumber(this.profile.phone);
      if (countryCodeParsedFromPhone) {
        countryOfOrigin = countryCodeParsedFromPhone;
      } else {
        logError({
          error: Error(
            `Cannot detect country from phone number of ${this.profile.phone}. User country code has been set to the default ${HARDCODED_VARS.DEFAULT_SENDER_COUNTRY} country code`,
          ),
          errorInfo: {
            phoneNumber: this.profile.phone,
          },
          userInfo: this.profile,
        });
      }
    }

    if (countryOfOrigin) {
      return countryOfOrigin;
    }

    return HARDCODED_VARS.DEFAULT_SENDER_COUNTRY as CountryCode;
  }

  get currentLanguage() {
    return getLanguageCodeByAvailable(this.profile.language || i18n.language);
  }

  get currentLanguageLocale() {
    return `${
      this.currentLanguage
    }-${this.userCountryOfOrigin.toLocaleLowerCase()}` as LanguageLocale;
  }

  get dob() {
    switch (true) {
      case !this.isLoading:
        return this.financeProfile?.dateOfBirth ?? this.profile.dateOfBirth;
      case this.isLoaded:
        return this.financeProfile?.dateOfBirth ?? this.profile.dateOfBirth;
      default:
        return undefined;
    }
  }

  get shortName() {
    switch (true) {
      case !this.isLoading:
      case this.isLoaded:
        return this.financeProfile?.shortName ?? this.profile.shortName;
      default:
        return undefined;
    }
  }

  get fullName() {
    switch (true) {
      case !this.isLoading:
      case this.isLoaded:
        return this.financeProfile?.fullName ?? this.profile.fullName;
      default:
        return undefined;
    }
  }
}

export default UserAccountStore;
