import { FC, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';

import { Grid, Button, Modal, PromoCodeForm, Logo, Typography } from '@components/common';

import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';
import PaymentCardClass from '@root/models/PaymentCardClass';

import CountryCode from '@root/interfaces/CountryCode';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrButton from '@components/common/BrButton';
import CallingBalanceInfo from '@components/common/CallingBalanceInfo';
import AutoRechargeToggle from '@components/common/CallingAutoRechargeToggle';
import CallingRechargeAmountList from '@components/common/CallingRechargeAmountList';
import BrPaymentCardSelect from '@components/common/BrPaymentCardSelect';
import BrExpiredPaymentCardUpdateUi from '@components/common/BrPaymentCardSelect/BrExpiredPaymentCardUpdateUi';

import { getFormattedAmount } from '@helpers/money';

import useModal from '@root/hooks/useModal';

import { capitalizeFirstLetterInEachWord } from '@utils/string';

import {
  PaymentCardFundingType,
  PaymentCardFundingTypeMap,
} from '@root/constants/moneyTransfer/paymentCards';

import { showBrToast } from '@services/toasts';

import AutoRechargeButton from './components/AutoRechargeButton';
import ManualPromoDescription from './components/ManualPromoDescription';

import Store from './Store';

export interface RechargeFinishParams {
  amount: number;
  isAutoRechargeEnabled: boolean;
  paymentCard: PaymentCardClass;
  validatedPromoCode?: string;
}

interface Props {
  isLoading?: boolean;
  dataTestPrefix?: string;
  paymentInfo: CallingPaymentInfoClass;
  isAutoRechargeEnabled: boolean;
  isCallingFeatureAvailable?: boolean;
  userCountryOfOrigin: CountryCode;
  onFinish: (params: RechargeFinishParams) => void;
  onManageArClick: () => void;
}

const Recharge: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    onFinish,
    paymentInfo,
    isAutoRechargeEnabled,
    userCountryOfOrigin,
    onManageArClick,
    dataTestPrefix,
    isCallingFeatureAvailable,
    isLoading: isRechargeLoading,
  } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        isAutoRechargeEnabled,
        defaultSelectedAmount:
          paymentInfo.defaultRechargeAmount || paymentInfo.creditCardTiers[0].price,
      }),
  );

  const { showModal, closeModal, setModalHeader, setModalContent } = useModal();

  const DATA_TEST_ID_PREFIX = `${dataTestPrefix}-recharge`;

  const doPurchase = (card: PaymentCardClass) => {
    onFinish({
      amount: store.selectedAmount,
      isAutoRechargeEnabled: store.isAutoRechargeEnabled,
      paymentCard: card,
      validatedPromoCode: store.validatedPromoCode,
    });
  };

  const handleOnCardAdded = (card: PaymentCardClass) => {
    doPurchase(card);
  };

  const handleOnCardEdited = (card: PaymentCardClass) => {
    doPurchase(card);
  };

  const {
    selectProps,
    paymentCardReducedDetailsDialogProps,
    selectedPaymentCard,
    showPaymentCardForm,
    isLoading: isCardsLoading,
  } = BrPaymentCardSelect.useBrPaymentCardSelect({
    handleId: paymentInfo.handleId,
    isUsingCardsWithoutBillingAllowed: true,
    userCountryOfOrigin,
    onCardAdded: handleOnCardAdded,
    onCardEdited: handleOnCardEdited,
  });

  const showExpiredPaymentCardModal = (card: PaymentCardClass) => {
    const handleOnAdd = () => {
      showPaymentCardForm();
      selectProps.onCardAdd?.();
      closeModal();
    };

    const handleOnEdit = () => {
      showPaymentCardForm();
      selectProps.onCardEdit?.(card.id);
      closeModal();
    };

    const fundingType = capitalizeFirstLetterInEachWord(
      PaymentCardFundingTypeMap[card.type],
    ) as PaymentCardFundingType;

    setModalHeader(t('Expired payment card'));
    setModalContent(
      <BrExpiredPaymentCardUpdateUi
        maskedNumber={card.maskedNumber}
        onAdd={handleOnAdd}
        onUpdate={handleOnEdit}
        fundingType={fundingType}
      />,
    );
    showModal();
  };

  const isPaymentCardFormOpened = paymentCardReducedDetailsDialogProps.isOpen;

  // Set Default recharge amount from the config
  useEffect(() => {
    const defaultAmountToSelect =
      paymentInfo.defaultRechargeAmount || paymentInfo.creditCardTiers[0].price;
    store.setSelectedAmount(defaultAmountToSelect);
  }, [paymentInfo.creditCardTiers, paymentInfo.defaultRechargeAmount, store]);

  const handleAmountSelect = async (value: string) => {
    store.resetPromo();
    store.setSelectedAmount(value);
  };

  // validate promo code on amount change
  useAsyncEffect(async () => {
    const currentPromoCode =
      store.appliedPromoCode ||
      (store.isAutoRechargeEnabled && paymentInfo.arPromoInfo?.code) ||
      undefined;

    if (currentPromoCode) {
      await store.getPromoInfoByCode({
        code: currentPromoCode,
        isArPromo:
          store.isAutoRechargeEnabled &&
          paymentInfo.arPromoInfo?.code === currentPromoCode,
      });
    }
  }, [store.selectedAmount]);

  const handleApplyPromo = async (value: string) => {
    await store.setAppliedPromoCode(value);
    await store.getPromoInfoByCode({
      code: value,
    });
  };

  const handleConfirmClick = () => {
    if (selectedPaymentCard) {
      if (selectedPaymentCard?.isExpired) {
        showExpiredPaymentCardModal(selectedPaymentCard);
        return undefined;
      }
      if (store.selectedAmount) {
        onFinish({
          amount: store.selectedAmount,
          isAutoRechargeEnabled: store.isAutoRechargeEnabled,
          paymentCard: selectedPaymentCard,
          validatedPromoCode: store.validatedPromoCode,
        });
        return undefined;
      }
    } else {
      showBrToast.small(t('Select a card'));
    }
    return undefined;
  };

  const handlePromoCodeFormValuesChange = async () => {
    store.setAppliedPromoCode(undefined);
    if (store.promoInfo?.code !== paymentInfo.arPromoInfo?.code) {
      store.resetPromo();
      if (paymentInfo.arPromoInfo?.code && store.isAutoRechargeEnabled) {
        await store.getPromoInfoByCode({
          code: paymentInfo.arPromoInfo?.code,
          isArPromo: true,
        });
      }
    }
  };

  const handleArRecommendedToggleChange = async (value: boolean) => {
    if (value) {
      store.resetPromo();
      store.setIsAutoRechargeEnabled(value);
      if (paymentInfo.arPromoInfo?.code) {
        await store.getPromoInfoByCode({
          code: paymentInfo.arPromoInfo?.code,
          isArPromo: true,
        });
      }
    } else if (paymentInfo.arPromoInfo?.code) {
      store.showArTurnOffConfirmationModal();
    } else {
      store.resetPromo();
      store.setIsAutoRechargeEnabled(false);
    }
  };

  const handleArTurnOffConfirm = () => {
    store.resetPromo();
    store.setIsAutoRechargeEnabled(false);
    store.closeArTurnOffConfirmationModal();
  };

  const getDisplayPromoAmount = () => {
    if (store.promoInfo?.amount) {
      return `${store.promoInfo.currencySymbol}${getFormattedAmount(
        store.promoInfo.amount,
      )} ${store.promoInfo.currency}`;
    }

    return undefined;
  };

  return (
    <Grid.Container className="flex flex-col">
      <div>
        <CallingBalanceInfo
          title={t('Your current balance is')}
          currencySymbol={paymentInfo.currencySymbol}
          currency={paymentInfo.currency}
          amount={paymentInfo.balanceAmount}
        />
      </div>

      {isCallingFeatureAvailable && (
        <>
          <div className="text-center my-xlarge">
            <p className="text-headers/header-5 mb-middle">
              {t('Select a recharge amount')}
            </p>
            <CallingRechargeAmountList
              value={store.selectedAmount}
              options={paymentInfo.creditCardTiers}
              onChange={handleAmountSelect}
              displayPromoAmount={getDisplayPromoAmount()}
              dataTestId={DATA_TEST_ID_PREFIX}
            />
          </div>

          <div className="text-center">
            {paymentInfo.isAutoRechargeRecommended ? (
              <AutoRechargeToggle
                value={store.isAutoRechargeEnabled}
                onChange={handleArRecommendedToggleChange}
                bonusPercentage={store.arPromoBonusPercent}
                className="mb-xsmall"
                dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
              />
            ) : (
              <div className="flex justify-center">
                <AutoRechargeButton
                  onClick={onManageArClick}
                  isArEnabled={store.isAutoRechargeEnabled}
                  dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
                />
              </div>
            )}

            <div className="flex justify-center mb-xlarge">
              <BrButton
                cmpType="link"
                onClick={store.showArDescriptionModal}
                data-test-id={`${DATA_TEST_ID_PREFIX}-link`}
                text={t('What is auto-recharge?')}
              />
            </div>
          </div>

          <div className="text-center mb-middle">
            <div className="m-auto max-w-[290px]">
              <PromoCodeForm
                onFormValuesChange={handlePromoCodeFormValuesChange}
                onSubmit={handleApplyPromo}
                placeholder={t('Enter promo code')}
                isApplied={Boolean(store.validatedPromoCode && store.appliedPromoCode)}
                isLoading={store.isPromoValidationLoading}
                dataTestPrefix={DATA_TEST_ID_PREFIX}
              />

              {store.promoInfo && !store.promoInfo.isAutoRechargePromo && (
                <ManualPromoDescription promoInfo={store.promoInfo} />
              )}
            </div>
          </div>

          <div className="m-auto my-xlarge w-full">
            <div className="text-center text-headers/header-5 mb-default">
              {t('Payment Details')}
            </div>

            {isPaymentCardFormOpened ? (
              <BrPaymentCardSelect.BrPaymentCardReducedDetailsForm
                {...paymentCardReducedDetailsDialogProps}
                dataTestPrefixId="br-payment-card-form"
                submitBtnText={
                  paymentCardReducedDetailsDialogProps.isEdit
                    ? t('Update card & complete oder')
                    : t('Add card & complete order')
                }
              />
            ) : (
              <>
                <BrPaymentCardSelect {...selectProps} />
                <BrBottomControlsWrapper className="mt-default">
                  <BrButton
                    onClick={handleConfirmClick}
                    dataTestId={`${DATA_TEST_ID_PREFIX}-сonfirm-adding`}
                    className="w-full"
                    state={isCardsLoading || isRechargeLoading ? 'loading' : 'default'}
                    text={t('Add {{amount}} to balance', {
                      amount: `${paymentInfo.currencySymbol}${store.selectedAmount} ${paymentInfo.currency}`,
                    })}
                  />
                </BrBottomControlsWrapper>
              </>
            )}
          </div>

          {/* Auto Recharge Description Modal */}
          <Modal
            isOpen={store.isArDescriptionModalOpen}
            onRequestClose={store.closeArDescriptionModal}
            header={<Logo className="w-32" />}
          >
            <Typography.Title level={5} className="mb-6">
              {t('What is Auto-Recharge?')}
            </Typography.Title>
            <div className="text-xl">
              {t(
                `Never get off in the middle of a call. If your balance falls below {{thresholdAmount}} your account will automatically be recharged by {{rechargeAmount}} or change it to a different amount in settings.`,
                {
                  thresholdAmount: `${paymentInfo.currencySymbol}${paymentInfo.thresholdAmount} ${paymentInfo.currency}`,
                  rechargeAmount: `${paymentInfo.currencySymbol}${store.selectedAmount} ${paymentInfo.currency}`,
                },
              )}
            </div>
          </Modal>

          {/* Auto Recharge Turn Off Confirmation Modal */}
          <Modal
            isOpen={store.isArTurnOfConfirmationModalOpen}
            onRequestClose={store.closeArTurnOffConfirmationModal}
            header={<Logo className="w-32" />}
          >
            <Typography.Title level={5} className="mb-6">
              {t(
                'Wait! Do you really want to miss out on a {{displayBonusAmount}} Bonus?',
                {
                  displayBonusAmount: getDisplayPromoAmount(),
                },
              )}
            </Typography.Title>
            <div className="mb-6 text-xl">
              {t(
                "Don't miss out on your {{arPromoBonusPercent}}% bonus of {{displayBonusAmount}} by turning off Auto-Recharge",
                {
                  arPromoBonusPercent: store.arPromoBonusPercent,
                  displayBonusAmount: getDisplayPromoAmount(),
                },
              )}
            </div>
            <div className="flex items-center">
              <Button
                shape="pill"
                size="lg"
                className="m-2 w-1/2"
                onClick={handleArTurnOffConfirm}
              >
                {t('Turn Off')}
              </Button>
              <Button
                shape="pill"
                size="lg"
                className="m-2 w-1/2"
                onClick={store.closeArTurnOffConfirmationModal}
              >
                {t('Keep On')}
              </Button>
            </div>
          </Modal>
        </>
      )}
    </Grid.Container>
  );
};

export default observer(Recharge);
