// eslint-disable-next-line import/prefer-default-export
export function removeDuplicates<T>(myArr: T[], prop?: keyof T): T[] {
  if (prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  return myArr.filter((item, index) => myArr.indexOf(item) === index);
}

export function groupBy<T>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } {
  return arr.reduce((storage, item) => {
    const objKey = keys.map((key) => `${item[key]}`).join(':');
    if (storage[objKey]) {
      storage[objKey].push(item);
    } else {
      // eslint-disable-next-line no-param-reassign
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: T[] });
}

export function getSortByKeyFn<T>(key: keyof T): (item1: T, item2: T) => number {
  return (item1, item2) => {
    const keyValue1 = item1[key];
    const keyValue2 = item2[key];
    if (keyValue1 && keyValue2) {
      if (keyValue1 < keyValue2) {
        return -1;
      }
      if (keyValue1 > keyValue2) {
        return 1;
      }
    }
    return 0;
  };
}

/**
 * Added additional function because getSortByKeyFn doesn't work correctly with getter/setter keys.
 * This function properly handles accessor properties by invoking the getter before comparison.
 */
export function getSortForKey<T, K extends keyof T>(
  key: K,
  isReverseSort = false,
): (item1: T, item2: T) => number {
  return (item1, item2) => {
    const keyValue1 =
      typeof item1[key] === 'function'
        ? ((item1[key] as unknown) as () => string | number | boolean)()
        : item1[key];
    const keyValue2 =
      typeof item2[key] === 'function'
        ? ((item2[key] as unknown) as () => string | number | boolean)()
        : item2[key];

    let comparison = 0;

    if (keyValue1 < keyValue2) {
      comparison = -1;
    } else if (keyValue1 > keyValue2) {
      comparison = 1;
    }

    return isReverseSort ? -comparison : comparison;
  };
}

export const filterOutEmptyItems = <T>(data: (T | undefined)[]): T[] => {
  return data.filter((item): item is T => {
    return Boolean(item);
  });
};
