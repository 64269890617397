export const PURCHASE_ITEM = {
  NAME_MT: 'MT',
  NAME_IMTU: 'MTU',
  PINLESS: 'Pinless',
  BRAND: 'BOSS Revolution',
  QUANTITY: 1,
  CURRENCY: 'USD',
};

export const ANALYTICS_PAYMENT_CARD_ERROR_MSG = {
  CVV_MISMATCH: 'CVV mismatch',
  ACCERTIFY: 'accertify',
  CC_MISMATCH: 'CC mismatch',
};

export enum GoogleAnalyticsEvent {
  Login = 'login',
  SignUp = 'sign_up',
  Action = 'action',
  Click = 'click',
  MtModalAppLinkClick = 'mt_apps_click_acc',
  ApplicationActivated = 'application_activated',
  MigrationBrcToDtc = 'brc_profile_migrated_to_dtc',
  BackendError = 'backend_error',
  BackedSuccess = 'backend_success',
  ButtonPress = 'button_press',
  Error = 'generic_error',
  Select = 'select',
  PageView = 'page_view',
  PurchaseDefault = 'purchase',
  PurchaseMt = 'purchase_MT',
  PurchaseImtu = 'purchase_MTU',
  PurchasePinless = 'purchase_Pinless',
  PurchasePlans = 'purchase_Plans',
  Refund = 'refund',
}

export enum BrazeEvent {
  SelectedCountry = 'selected_country',
  SelectedDeliveryMethod = 'selected_delivery_method',
  PurchaseMt = 'mt_purchase',
  PurchaseImtu = 'purchase',
}

export enum AnalyticsAction {
  Back = 'back',
  Exit = 'exit',
  Cancel = 'cancel',
}

export enum BackendFailureCategory {
  Mt = 'MT',
  Mtu = 'MTU',
  CallingPlans = 'CallingPlans',
}

export enum AnalyticsErrorCategory {
  MoneyTransfer = 'MT',
}

export enum AnalyticsErrorSubcategory {
  Card = 'Card',
  Compliance = 'Compliance',
}

export enum BackendFailureCallName {
  TransactionSetup = 'transaction_setup',
}

export enum SelectorName {
  DeliveryMethods = 'delivery_methods',
}
