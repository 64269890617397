import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

class ImtuRecentContactClass {
  countryCode: CountryCode;

  carrierCode: string;

  phoneNumber: string;

  promoIds: string[];

  timestamp: number;

  constructor(data: ImtuRecentContactClass) {
    makeAutoObservable(this);

    this.countryCode = data.countryCode;
    this.carrierCode = data.carrierCode;
    this.phoneNumber = data.phoneNumber;
    this.promoIds = data.promoIds;
    this.timestamp = data.timestamp;
  }
}

export default ImtuRecentContactClass;
