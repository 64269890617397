import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { UserAvatar } from '@components/common';

import useStore from '@root/hooks/useStore';
import { AppConfigStore, UserAccountStore } from '@root/stores';

import { normalizeStringCompound } from '@utils/string';

import ROUTE_PATHS from '@root/routes/paths';

import AccountOptionButton, {
  AccountOptionButtonProps,
} from './components/AccountOptionButton';
import FooterSection from './components/FooterSection';

type Button = AccountOptionButtonProps & { isHidden?: boolean };

const DATA_TEST_ID_PREFIX = 'user-account';

const UserAccount = () => {
  const { t } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const availableFeatures = appConfigStore.availableMainFeaturesCfg;

  const config: Button[] = [
    {
      title: t('Mobile Top-Up'),
      iconName: 'top-up-outline',
      link: ROUTE_PATHS.IMTU,
      dataTestId: `${DATA_TEST_ID_PREFIX}-mtu-btn`,
      isHidden: !availableFeatures.isImtuAvailable,
    },
    {
      title: t('Calling Plans'),
      iconName: 'plans-outline',
      isNew: true,
      link: ROUTE_PATHS.CALLING_PLANS,
      dataTestId: `${DATA_TEST_ID_PREFIX}-calling-plans-btn`,
      isHidden: !availableFeatures.isCallingPlansAvailable,
    },
    {
      title: t('Calling Recharge'),
      iconName: 'recharge',
      link: ROUTE_PATHS.CALLING,
      dataTestId: `${DATA_TEST_ID_PREFIX}-calling-btn`,
      isHidden: !availableFeatures.isCallingAvailable,
    },
    {
      title: t('Money Transfer'),
      iconName: 'money-outline',
      link: ROUTE_PATHS.MT_MODAL,
      dataTestId: `${DATA_TEST_ID_PREFIX}-mt-btn`,
      isHidden: !appConfigStore.isMtAvailableForCountry(
        userAccountStore.userCountryOfOrigin,
      ),
    },
  ];

  const welcomeText = normalizeStringCompound(
    [t('Welcome'), userAccountStore.shortName],
    ', ',
  );
  return (
    <div className="space-y-x4large">
      <div className="text-center space-y-default">
        <h3 className="text-headers/header-2">{welcomeText}</h3>
        <div className="mx-auto h-[100px] w-[100px]">
          <UserAvatar
            avatarUrl={userAccountStore.profile.avatarURL}
            altText={userAccountStore.shortName}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-small">
        {config.map(
          (item) =>
            !item.isHidden && (
              <AccountOptionButton
                key={item.title}
                {...item}
                className="account-option-button"
              />
            ),
        )}
      </div>
      <FooterSection />
    </div>
  );
};

export default observer(UserAccount);
