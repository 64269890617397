import { useTranslation } from 'react-i18next';

import { Grid } from '@components/common';

import BASE_PATHS from '@root/routes/paths';

import { SUPPORT_URL_TEMPLATES } from '@root/constants/storeUrls';
import { defaultMainFeatures } from '@root/constants/appConfig';

import { getFallbackLocaleForLegalUrls } from '@helpers/locale';

import LogotypeLink from '../LogotypeLink';
import UserAvatarDropdownMenu from '../UserAvatarDropdownMenu';
import MenuList from './MenuList';

import { NavigationListItemProps, NavigationProps } from '../../types';

const DATA_TEST_ID_PREFIX = 'navigation-header';

const TopNavigationBar = (props: NavigationProps) => {
  const {
    avatarAltText,
    avatarUrl,
    languageLocale,
    featureAvailability = defaultMainFeatures,
    languagesOptions,
    onLogoutClick,
  } = props;

  const { t } = useTranslation();

  const fallbackLocale = getFallbackLocaleForLegalUrls(languageLocale);

  const NAVIGATION_ITEM_IDS = {
    CALLING: 'calling',
    CALLING_PLANS: 'calling-plans',
    MT: 'send-money',
    IMTU: 'top-up',
    SUPPORT: 'support',
  };

  // TODO: rework to helpers like it is done in Footer component
  const navigationItemIdsToFeatureAvailabilityMap = {
    [NAVIGATION_ITEM_IDS.CALLING]: featureAvailability.isCallingAvailable,
    [NAVIGATION_ITEM_IDS.CALLING_PLANS]: featureAvailability.isCallingPlansAvailable,
    [NAVIGATION_ITEM_IDS.MT]: featureAvailability.isMtAvailable,
    [NAVIGATION_ITEM_IDS.IMTU]: featureAvailability.isImtuAvailable,
    [NAVIGATION_ITEM_IDS.SUPPORT]: true, // support is always visible
  };

  const navigationScheme: NavigationListItemProps = [
    {
      id: NAVIGATION_ITEM_IDS.IMTU,
      path: BASE_PATHS.IMTU,
      name: t('Top-Up'),
    },
    {
      id: NAVIGATION_ITEM_IDS.CALLING_PLANS,
      path: BASE_PATHS.CALLING_PLANS,
      name: t('Calling Plans'),
      isNew: true,
    },
    {
      id: NAVIGATION_ITEM_IDS.CALLING,
      path: BASE_PATHS.CALLING,
      name: t('Calling Recharge'),
    },
    {
      id: NAVIGATION_ITEM_IDS.MT,
      path: BASE_PATHS.MT_MODAL,
      name: t('Money Transfer'),
    },
    {
      id: NAVIGATION_ITEM_IDS.SUPPORT,
      path: SUPPORT_URL_TEMPLATES.FAQ.replace('{{langLocale}}', fallbackLocale),
      name: t('Support'),
      isNative: true,
    },
  ].filter((item) => {
    // filter out not supported features
    return navigationItemIdsToFeatureAvailabilityMap[item.id];
  });

  return (
    <Grid.Row className="items-center h-16 md:h-12.5">
      <Grid.Col span={{ md: 2, lg: 3 }}>
        <LogotypeLink languageLocale={fallbackLocale} />
      </Grid.Col>
      <Grid.Col span={{ md: 8, lg: 6 }}>
        <MenuList links={navigationScheme} dataTestPrefix={DATA_TEST_ID_PREFIX} />
      </Grid.Col>
      <Grid.Col span={{ md: 2, lg: 3 }} className="self-stretch">
        <UserAvatarDropdownMenu
          avatarAltText={avatarAltText}
          userAvatarUrl={avatarUrl}
          languagesOptions={languagesOptions}
          onLogoutClick={onLogoutClick}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default TopNavigationBar;
