import { makeAutoObservable } from 'mobx';

import { ImtuTransactionQueryParams } from '@helpers/urlQuery';
import { getPhoneInfoByNumber } from '@helpers/phone';

import { logError } from '@services/logging';
import { fetchImtuCarriers, fetchImtuOffers } from '@services/api/v1/dtcImtuK2';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';

import CountryCode from '@root/interfaces/CountryCode';

class ImtuPrefillStore {
  countryCode?: CountryCode;

  recipientPhone?: string;

  carrier?: ImtuCarrierClass;

  product?: ImtuProductClass;

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCountryCode(countryCode?: CountryCode) {
    this.countryCode = countryCode;
  }

  setRecipientPhone(phone?: string) {
    this.recipientPhone = phone;
  }

  setCarrier(carrier?: ImtuCarrierClass) {
    this.carrier = carrier;
  }

  setProduct(product?: ImtuProductClass) {
    this.product = product;
  }

  setDataFromQueryParams = async (data: ImtuTransactionQueryParams) => {
    try {
      this.isLoading = true;
      if (data.phoneNumber) {
        this.setRecipientPhone(data.phoneNumber);
        const parsedCountryCode = getPhoneInfoByNumber(data.phoneNumber).country;
        if (parsedCountryCode) {
          this.setCountryCode(parsedCountryCode);
          if (data.carrierId) {
            const carriersRes = await fetchImtuCarriers(data.phoneNumber);
            const carrierSearchResult = carriersRes.carriers.find((item) => {
              return data.carrierId === item.code;
            });
            if (carrierSearchResult) {
              this.setCarrier(carrierSearchResult);
              if (data.productId) {
                const products = await fetchImtuOffers({
                  recipientPhone: data.phoneNumber,
                  carrierCode: data.carrierId,
                });
                const productsSearchResult = products.find((item) => {
                  return data.productId === item.code;
                });
                if (productsSearchResult) {
                  this.setProduct(productsSearchResult);
                }
              }
            }
          }
        }
      }
    } catch (err) {
      logError({
        error: Error('Imtu deep link error'),
        errorInfo: err,
      });
    } finally {
      this.isLoading = false;
    }
  };
}

export default ImtuPrefillStore;
