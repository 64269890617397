// eslint-disable-next-line import/prefer-default-export
export const DTC_IMTU_K2_ENDPOINTS = {
  CONFIG: '/mtu/v1/config',
  PROMOS: '/mtu/v1/promos',
  PROMOS_v2: '/mtu/v2/promos',
  OFFERS: '/mtu/v1/offers',
  CARRIER_PROMO_OFFERS: '/store/v1/offers/mtu/carrier',
  TRANSACTIONS: '/mtu/v1/transactions',
  PROMO_CHECK: '/store/v1/offers/mtu',
  PURCHASES: '/store/v1/purchases',
};
