import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserAccountStore } from '@root/stores';
import FundingHistoryStore from '@root/stores/FundingHistoryStore';
import CallingRechargeStore from '@root/stores/CallingRechargeStore';
import PaymentCardStore from '@root/stores/PaymentCardStore';

import BASE_PATHS from '@root/routes/paths';

import useStore from '@root/hooks/useStore';
import useDidMount from '@root/hooks/useDidMount';

import CallingBalanceInfo from '@components/common/CallingBalanceInfo';
import CallingAutoRechargePromoInfo from '@components/common/CallingAutoRechargePromoInfo';

import { Grid } from '@components/common';
import BrButton from '@components/common/BrButton';

import Receipt from './components/Receipt';
import ReceiptSkeleton from './components/ReceiptSkeleton';

import useCallingRechargeTxnParams from './useCallingRechargeTxnParams';

interface Props {
  txnId: string;
}

const CallingRechargeReceipt: React.FunctionComponent<Props> = (props: Props) => {
  const { txnId } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const fundingHistoryStore = useLocalStore(() => new FundingHistoryStore());
  const paymentCardStore = useLocalStore(() => new PaymentCardStore());
  const store = useLocalStore(() => new CallingRechargeStore());

  const txnParams = useCallingRechargeTxnParams();

  useDidMount(async () => {
    await Promise.all([
      fundingHistoryStore.getTransactions(),
      store.getCallingInfo(),
      txnParams?.cardHandleId
        ? paymentCardStore.getEditableCardByHandleId(txnParams?.cardHandleId)
        : undefined,
    ]);
  });

  const txn = fundingHistoryStore.getTxnById(txnId);

  const handleManageArClick = () => {
    history.push(BASE_PATHS.CALLING_AUTO_RECHARGE);
  };

  const isDataLoading =
    userAccountStore.isLoading ||
    fundingHistoryStore.isLoading ||
    paymentCardStore.isAnythingLoading;

  return (
    <>
      <div className="mt-10 mb-24">
        <Grid.Container>
          <Grid.Row>
            <Grid.Col span={{ sm: 12, md: 10, lg: 10 }} offset={{ md: 1, lg: 1 }}>
              {isDataLoading ? (
                <ReceiptSkeleton />
              ) : (
                <>
                  {txn && (
                    <>
                      {txnParams?.newBalance && (
                        <CallingBalanceInfo
                          title={t('Your new balance is')}
                          currencySymbol={txn.currencySymbol}
                          currency={txn.currency}
                          amount={txnParams.newBalance}
                          className="mb-4"
                        />
                      )}
                      <Receipt
                        currencySymbol={txn.currencySymbol}
                        userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
                        currency={txn.currency}
                        rechargeAmount={txn.amount}
                        date={txn.date}
                        oldBalance={txnParams?.oldBalance}
                        newBalance={txnParams?.newBalance}
                        promoAmount={txnParams?.promoAmount}
                        cardNumber={paymentCardStore.editableCard?.maskedNumber}
                      />

                      {(store.paymentInfo?.arPromoInfo?.description ||
                        store.paymentInfo?.arPromoBullets) && (
                        <CallingAutoRechargePromoInfo
                          title={store.paymentInfo.arPromoInfo?.description}
                          description={store.paymentInfo.arPromoBullets}
                          className="text-center mb-5"
                        />
                      )}

                      <div className="justify-center">
                        <BrButton
                          className="w-full"
                          onClick={handleManageArClick}
                          dataTestId="confirmation"
                          text={t('Manage auto-recharge')}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </div>
    </>
  );
};

export default observer(CallingRechargeReceipt);
