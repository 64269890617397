import React from 'react';
import { useTranslation } from '@nodeModules/react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrCountrySelect from '@components/common/BrCountrySelect';
import BrButton from '@components/common/BrButton';

import { getCountryNameByCode } from '@services/countryList';
import { showToast } from '@services/toasts';

import Store from './Store';
import { VALIDATION_ERRORS } from './constants';

import CountrySkeleton from './components/CountrySkeleton';

interface Props extends StepModule {
  selectedCountryCode?: CountryCode;
  countries: CountryCode[];
  onFinish(args: { countryCode: CountryCode }): void;
}

const Country: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { selectedCountryCode, countries, dataTestPrefix, onFinish } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        countryCode: selectedCountryCode,
      }),
  );

  const handleFinish = () => {
    if (store.countryCode) {
      onFinish({
        countryCode: store.countryCode,
      });
    } else {
      showToast.warning(VALIDATION_ERRORS.COUNTRY_NOT_SELECTED);
    }
  };

  const handleCountrySelect = (value: string) => {
    store.setCountryCode(value as CountryCode); // TODO: revisit select interface to make it's value to be generic
    handleFinish(); // automatically finish the step on country selection
  };

  return (
    <div>
      {countries.length ? (
        <>
          <h5 className="text-body/primary/demibold mb-small first-letter:capitalize">
            {t("select the recipient's country")}
          </h5>
          <div className="mb-xlarge">
            <BrCountrySelect
              placeholder={t('Select or type a country')}
              isOpened={!store.countryCode}
              hasSearch
              data={countries.map((item) => {
                return {
                  code: item,
                  value: item,
                  textLabel: t(getCountryNameByCode(item) || ''),
                };
              })}
              value={store.countryCode || ''}
              onChange={handleCountrySelect}
              dropdownOptionHeight={48}
            />
          </div>
        </>
      ) : (
        <CountrySkeleton />
      )}

      <BrBottomControlsWrapper>
        <BrButton
          onClick={handleFinish}
          text={t('Continue')}
          className="w-full"
          dataTestId={`${dataTestPrefix}-next-btn`}
        />
      </BrBottomControlsWrapper>
    </div>
  );
};

export default observer(Country);
