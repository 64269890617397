import { forwardRef, useImperativeHandle } from 'react';
import { normalizeStringCompound } from '@utils/string';

import Scrollbar from '../Scrollbar';

import useBrScrollShadowWrapperLogic from './useBrScrollShadowWrapperLogic';

import './styles.scss';

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const BrScrollShadowWrapper = forwardRef(
  (
    props: Props,
    ref: React.ForwardedRef<{ scrollableNodeRef: React.RefObject<HTMLDivElement> }>,
  ) => {
    const { className, children } = props;

    const {
      scrollShadowWrapperRef,
      topShadowStyles,
      bottomShadowStyles,
      scrollableNodeRef,
      scrollShadowWrapperHeight,
      scrollableContentRef,
    } = useBrScrollShadowWrapperLogic();

    useImperativeHandle(ref, () => ({
      scrollableNodeRef,
    }));

    return (
      <div
        ref={scrollShadowWrapperRef}
        className={normalizeStringCompound([
          `br-scroll-shadow-wrapper relative overflow-hidden -mx-large px-large md:-mx-default md:px-default lg:-mx-xlarge lg:px-xlarge`,
          className,
        ])}
      >
        <div className={topShadowStyles} />
        <Scrollbar
          autoHide={false}
          scrollableNodeProps={{ ref: scrollableNodeRef }}
          style={{
            height: scrollShadowWrapperHeight,
          }}
        >
          <div ref={scrollableContentRef}>{children}</div>
        </Scrollbar>
        <div className={bottomShadowStyles} />
      </div>
    );
  },
);

export default BrScrollShadowWrapper;
