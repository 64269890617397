import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import { formatMoneyToText } from '@helpers/money';
import ImtuPromoV2Class from '@root/models/ImtuPromoV2Class';

export interface ImtuProductClassParams {
  code: string;
  name: string;
  senderCurrency: string;
  senderAmount: number;
  senderTotalAmount: number;
  carrier: ImtuCarrierClass;
  countryCode: CountryCode;
  fee?: number;
  recipientAmount?: number;
  recipientCurrency?: string;
  description?: string;
  carrierPromo?: ImtuPromoV2Class;
  priority?: number;
}

class ImtuProductClass {
  code = '-1';

  classId = '-1';

  countryCode?: CountryCode;

  name = '';

  description?: string;

  carrier = new ImtuCarrierClass();

  senderAmount = 0;

  senderTotalAmount = 0;

  senderCurrency = '';

  recipientAmount = 0;

  recipientCurrency = '';

  fee = 0;

  mask = '';

  priority?: number;

  carrierPromo?: ImtuPromoV2Class;

  constructor(data: ImtuProductClassParams) {
    makeAutoObservable(this);
    this.code = data.code;
    this.countryCode = data.countryCode;
    this.name = data.name;
    this.carrier = data.carrier;
    this.senderCurrency = data.senderCurrency;

    this.senderAmount = data.senderAmount;
    this.senderTotalAmount = data.senderTotalAmount;

    this.priority = data.priority;

    this.carrierPromo = data.carrierPromo;

    this.fee = data.fee || this.fee;

    this.recipientAmount = data.recipientAmount || this.recipientAmount;
    this.recipientCurrency = data.recipientCurrency || this.recipientCurrency;
    this.description = data.description;
  }

  get hasSameSenderRecipientAmount() {
    return this.recipientAmountText === this.senderBaseAmountText;
  }

  get senderTotalAmountText() {
    return formatMoneyToText(this.senderTotalAmount, this.senderCurrency);
  }

  get recipientAmountText() {
    return formatMoneyToText(this.recipientAmount, this.recipientCurrency);
  }

  get senderBaseAmountText() {
    return formatMoneyToText(this.senderAmount, this.senderCurrency);
  }

  get feeText() {
    return formatMoneyToText(this.fee, this.senderCurrency);
  }
}

export default ImtuProductClass;
