import { FC } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { Img } from 'react-image';

import CountryCode from '@root/interfaces/CountryCode';

import { ReactComponent as UserIcon } from '@src/static/assets/images/icons/legacyIcons/legacy-user-circle.svg';

import { normalizeStringCompound } from '@utils/string';

import BrBadge from '../BrBadge';
import Flag from '../Flag';

import './styles.scss';

const BASIC_CLASS = 'br-contacts';

export interface BrContactsProps {
  cmpSize?: 'default' | 'large' | 'medium' | 'small';
  cmpType?: 'person' | 'flag' | 'initials' | 'country img';
  countryCode?: CountryCode;
  imageUrl?: string;
  name?: string;
  surname?: string;
  phoneNumber?: string;
  promoText?: string;
  className?: string;
}

const generateInitials = (name: string, surname: string): string => {
  const initials = name[0] + surname[0];
  return initials;
};

const BrContacts: FC<BrContactsProps> = ({
  cmpSize,
  cmpType,
  countryCode,
  imageUrl,
  promoText,
  name,
  surname,
  phoneNumber,
  className,
}) => {
  const wrapperClassNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${cmpSize}`,
    `${BASIC_CLASS}-type-${cmpType}`,
    className,
  ]);

  const imageClassNames = normalizeStringCompound([
    'br-image',
    `br-image-${cmpSize}`,
    'relative',
    className,
  ]);

  const shouldRenderFlag = Boolean(
    countryCode && (cmpSize === 'default' || cmpSize === 'large') && cmpType !== 'flag',
  );
  const shouldRenderName = Boolean(
    cmpSize === 'large' || (cmpType !== 'flag' && cmpSize === 'default'),
  );
  const shouldRenderSurname = Boolean(cmpSize === 'default' && cmpType !== 'flag');

  const shouldRenderPhone = Boolean(
    cmpSize === 'large' || (cmpSize === 'default' && cmpType === 'flag'),
  );

  return (
    <div className={wrapperClassNames}>
      <div className="relative">
        <div className={imageClassNames}>
          {promoText && (cmpSize === 'default' || cmpSize === 'large') && (
            <BrBadge
              text={promoText}
              classType="warning"
              styleType="less-contrast-tail-right"
              className="absolute top-0 -right-[2px] z-10 -translate-y-1/2"
            />
          )}
          {(cmpType === 'country img' || cmpType === 'person') && (
            <div className="h-full w-full overflow-hidden rounded-full">
              {imageUrl && (
                <Img
                  src={imageUrl}
                  className="w-full h-full object-cover"
                  alt={name}
                  key={imageUrl}
                  loader={
                    <div className="justify-center flex h-full w-full items-center">
                      <LoaderIcon className="w-1/2 h-1/2 animate-spin" />
                    </div>
                  }
                  unloader={
                    <div className="w-full h-full">
                      <UserIcon className="w-full h-full" />
                    </div>
                  }
                />
              )}
              {shouldRenderFlag && (
                <Flag
                  className="w-[24px] h-[24px] absolute bottom-0 right-0 overflow-hidden rounded-full flex-shrink-0 self-center"
                  code={countryCode}
                />
              )}
            </div>
          )}
          {cmpType === 'flag' && (
            <Flag
              className="w-full h-full overflow-hidden rounded-full flex-shrink-0 self-center"
              code={countryCode}
            />
          )}

          {cmpType === 'initials' && name && surname && (
            <div className="br-initials">{generateInitials(name, surname)}</div>
          )}
        </div>
        {shouldRenderName && <div className={`br-name-${cmpSize}`}>{name}</div>}
        {shouldRenderSurname && <div className={`br-surname-${cmpSize}`}>{surname}</div>}
        {shouldRenderPhone && <div className={`br-phone-${cmpSize}`}>{phoneNumber}</div>}
      </div>
    </div>
  );
};

BrContacts.defaultProps = {
  cmpSize: 'default',
  cmpType: 'person',
};

export default BrContacts;
