import { ForwardedRef, ReactNode, forwardRef } from 'react';

import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import BrIllustration from '@components/common/BrIllustration';

import BrDrawer from '../BrDrawer';
import OptionList from './OptionList';

type RenderItem = {
  option: BrOptionProps;
  isActive: boolean;
  onSelect: (options: BrOptionProps) => void;
};
interface Props {
  title?: string;
  searchValue?: string;
  notFoundText?: string;
  hasSearch?: boolean;
  options: BrOptionProps[];
  selectedOption?: BrOptionProps;
  renderMobileSearchInput?: ReactNode;
  isOpen?: boolean;
  onAnimationEnd?(): void;
  onClose?(): void;
  onSelect(option: BrOptionProps): void;
  addonBottom?: React.ReactNode;
  renderItem?(item: RenderItem, itemIdx: number): JSX.Element;
}

const Drawer = forwardRef(
  (props: Props, ref: ForwardedRef<{ drawerRef: React.RefObject<HTMLDivElement> }>) => {
    const {
      options,
      notFoundText,
      renderMobileSearchInput,
      selectedOption,
      title,
      isOpen,
      onClose,
      onAnimationEnd,
      onSelect,
      renderItem,
      addonBottom,
    } = props;

    return (
      <BrDrawer
        isOpen={isOpen}
        ref={ref}
        onClose={onClose}
        onAnimationEnd={onAnimationEnd}
        title={title}
        addonTop={renderMobileSearchInput}
        addonBottom={addonBottom}
      >
        {options && (
          <OptionList
            options={options}
            renderItem={renderItem}
            onSelect={onSelect}
            selectedOption={selectedOption}
          />
        )}
        {!options.length && (
          <div className="min-h-[100px] w-full h-full flex flex-col items-center justify-center gap-small">
            <BrIllustration type="notFound" />
            <div className="text-body/caption/default text-support-colors/highlights">
              {notFoundText}
            </div>
          </div>
        )}
      </BrDrawer>
    );
  },
);

export default Drawer;
