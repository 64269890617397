import React, { FC } from 'react';
import { t } from 'i18next';

import { Typography } from '@components/common';
import BrBadge from '@components/common/BrBadge';

import { normalizeStringCompound } from '@utils/string';

import { NavigationListProps } from '../../types';

import './styles.scss';

const MenuList: FC<React.PropsWithChildren<NavigationListProps>> = (props) => {
  const { onItemSelect, className, dataTestPrefix, links } = props;

  const componentClassName = normalizeStringCompound(['flex', className]);

  const linkItemClassName =
    'flex w-full px-5 text-center place-items-center h-12.5 text-white hover:text-white main-navigation-item';

  const linkTextClassName =
    'w-full flex items-center justify-center h-full text-base font-medium lg:whitespace-nowrap';

  return (
    <ul className={componentClassName}>
      {/* TODO: put in separate list component */}
      {links.map((link) => {
        const handleItemClick = () => {
          if (onItemSelect) {
            onItemSelect();
          }
          if (link.onClick) {
            link.onClick();
          }
        };
        return (
          <li key={link.id} className="flex-grow leading-none">
            {link.path ? (
              <Typography.Link
                to={link.path}
                isNative={link.isNative}
                onClick={handleItemClick}
                className={linkItemClassName}
                activeClassName="text-white main-navigation-item_active"
                dataTestId={`${dataTestPrefix}-${link.id}`}
              >
                <span className={linkTextClassName}>
                  <span className="main-navigation-item__text h-full flex items-center">
                    {link.name}
                    {link.isNew && (
                      <BrBadge
                        classType="black"
                        styleType="less-contrast"
                        className="ml-small"
                        text={t('New')}
                      />
                    )}
                  </span>
                </span>
              </Typography.Link>
            ) : (
              <button
                onClick={handleItemClick}
                className={linkItemClassName}
                data-test-id={`${dataTestPrefix}-${link.id}`}
              >
                <span className={linkTextClassName}>
                  <span className="main-navigation-item__text h-full flex items-center">
                    {link.name}
                  </span>
                </span>
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default MenuList;
