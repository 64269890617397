import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import Icon from '@components/common/Icon';
import BrButton from '@components/common/BrButton';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import BrPaymentCardIssuerImg from '../BrPaymentCardIssuerImg';

interface Props {
  isSelected?: boolean;
  cardType: string;
  maskedNumber: string;
  className?: string;
  paySource: PaymentCardIssuerKeys;
  onEditClick?(value: string): void;
  isExpired?: boolean;
}

const BrOption = (props: Props) => {
  const {
    cardType,
    maskedNumber,
    className,
    paySource,
    onEditClick,
    isSelected,
    isExpired,
  } = props;

  const { t } = useTranslation();

  const handleOnEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEditClick?.('');
  };

  return (
    <div
      className={normalizeStringCompound([
        'flex bg-color/surface items-center h-full',
        className,
      ])}
    >
      <div className="w-xlarge h-xlarge flex-shrink-0">
        {isSelected && (
          <Icon
            name={isExpired ? 'close' : 'checkmark-circle'}
            width={24}
            height={24}
            className={normalizeStringCompound([
              isExpired ? 'text-color/error' : 'text-color/success',
              'flex-shrink-0',
            ])}
          />
        )}
      </div>
      <div className="flex-col flex ml-default w-full space-y-xxsmall">
        <div className="flex">
          <BrPaymentCardIssuerImg
            paySource={paySource}
            className="w-xlarge flex-shrink-0"
          />
          <div className="w-full text-left ml-small">
            <div
              className={normalizeStringCompound([
                'flex flex-grow text-headers/header-6 space-x-xsmall',
                isExpired ? 'text-on-colors/on-primary-variant' : '',
              ])}
            >
              <div>{cardType}</div>
              <div>{maskedNumber}</div>
            </div>
          </div>
        </div>
        {isExpired && (
          <span className="text-color/error text-left leading-none">
            {t('Card expired')}
          </span>
        )}
      </div>

      <BrButton
        cmpStyle="default"
        iconName="edit"
        cmpType="text"
        hasIconLeft
        onClick={handleOnEditClick}
        size="default"
        className="ml-default"
      />
    </div>
  );
};

export default BrOption;
