import { AsYouType, getCountryCallingCode } from 'libphonenumber-js';
import formatStringByPattern from 'format-string-by-pattern';

import CountryCode from '@root/interfaces/CountryCode';

import { defaultPhoneMask, uniqFormatMasks } from '@root/constants/phoneMasks';

export const getPhoneInfoByNumber = (phone: string) => {
  const normalizedPhone = phone[0] !== '+' ? `+${phone}` : phone;

  const asYouType = new AsYouType();
  asYouType.input(normalizedPhone);

  return asYouType;
};

export const isValidInternationalPhoneNumber = (phone: string) => {
  const phoneInfo = getPhoneInfoByNumber(phone).getNumber();
  return Boolean(phoneInfo?.isValid());
};

export const getPhoneDialCodeByCountryCode = (code?: CountryCode) => {
  if (!code || code === 'AN' || code === 'AQ') {
    return undefined;
  }
  return String(getCountryCallingCode(code));
};

type GetPhoneMaskByCountryCodeFnOptions = {
  shouldStripDialCode?: boolean;
};

export const getPhoneMaskByCountryCode = (
  code?: CountryCode,
  options?: GetPhoneMaskByCountryCodeFnOptions,
) => {
  if (code) {
    const dialCode = getPhoneDialCodeByCountryCode(code)?.replace(/[0-9]/g, '9');
    const format: undefined | string = uniqFormatMasks[code];

    if (dialCode && format) {
      // 9 is workaround (chrome autofilled phone number last digit is being cut)
      // this workaround lead to incorrect max width of an input but this is the lesser evil
      if (options?.shouldStripDialCode) {
        return `${format}9`;
      }

      return `${dialCode} ${format}9`;
    }
  }

  return defaultPhoneMask;
};

export const getCountryCodeByPhone = (
  fallbackCountryCode: CountryCode,
  phone?: string,
) => {
  if (!phone) {
    return fallbackCountryCode;
  }

  return getPhoneInfoByNumber(phone).country;
};

export const getCountryCodeByPhoneNumber = (phone?: string) => {
  if (phone) {
    return getPhoneInfoByNumber(phone).country;
  }
  return undefined;
};

export const formatPhone = (
  phoneNumber: string,
  fallbackCountryCode: CountryCode = 'US',
) => {
  const countryCode = getCountryCodeByPhone(fallbackCountryCode, phoneNumber);
  const phoneMask = getPhoneMaskByCountryCode(countryCode);

  return `+${formatStringByPattern(phoneMask, phoneNumber)}`;
};

/**
 * Strict phone format for sending money transfer transaction
 *
 * 1(415) 910-3333 -> +14159103333
 * 1(415) 910-3333 -> +14159103333
 *
 * result will be always +xxxxxxxxxxxx (up to 15 digits)
 * @param phoneStr
 */
export const formatPhoneToE164 = (phoneStr: string) => {
  const sanitizedPhone = phoneStr.replace(/[(\s)-]/g, '').trim();
  return sanitizedPhone.startsWith('+') ? sanitizedPhone : `+${sanitizedPhone}`;
};
