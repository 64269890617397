import { useTranslation } from 'react-i18next';

import BrButton from '@components/common/BrButton';

import { ReactComponent as CvvTipIllustration } from '@src/static/assets/images/cvv-tip.svg';

interface Props {
  onClose(): void;
}

const BrCvvModalContent = (props: Props) => {
  const { t } = useTranslation();

  const { onClose } = props;

  return (
    <>
      <div className="text-body/callout/default mb-default">
        {t(
          'Your CVV is a three or four-digit number on your credit card that adds an extra level of security.',
        )}
      </div>
      <CvvTipIllustration className="mb-default ml-auto mr-auto max-w-[326px]" />
      <BrButton className="w-full" onClick={onClose} text={t('I understand')} />
    </>
  );
};

export default BrCvvModalContent;
