import React, { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip, TooltipRefProps } from 'react-tooltip';

import useDidMount from '@root/hooks/useDidMount';

import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

// TODO: think on making Attention tooltips as a service like Toast
const HIDE_DELAY_FOR_DEFAULT_OPEN = 5000;

const BASIC_CLASS = 'br-tooltip';

const BrTooltip: React.FC<BrTooltipProps> = (props) => {
  const { color, defaultIsOpen: isDefaultOpen, className, anchorSelect } = props;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const ref = useRef<TooltipRefProps>(null);

  const portalEl = useMemo(() => {
    const PORTAL_EL_ID = 'tooltip-portal';
    const createPortalEl = (): HTMLDivElement => {
      const el = document.createElement('div');
      el.setAttribute('id', PORTAL_EL_ID);
      document.body.appendChild(el);
      return el;
    };

    return document.getElementById(PORTAL_EL_ID) || createPortalEl();
  }, []);

  useDidMount(() => {
    if (isDefaultOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, HIDE_DELAY_FOR_DEFAULT_OPEN);
    }
  });

  const cmpClassNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${color}`,
    className,
  ]);

  // Portal needed because react-tooltip doesn't handle overflow nicely
  return createPortal(
    <Tooltip
      {...props}
      anchorSelect={anchorSelect}
      isOpen={isOpen}
      className={cmpClassNames}
      delayHide={2000}
      ref={ref}
    />,
    portalEl,
  );
};

BrTooltip.defaultProps = {
  color: 'Default',
};

export default BrTooltip;
