import React from 'react';
import { useTranslation } from 'react-i18next';

import BrOfferCard from '@components/common/BrOfferCard';

import {
  CallingPlanDestination,
  CallingPlanFootnote,
} from '@root/models/CallingPlans/CallingPlan';

import PlanExpandableInfoBlock from './PlanExpandableInfoBlock';
import DestinationsExpandableList from './DestinationsExpandableList';

interface Props extends React.ComponentProps<typeof BrOfferCard> {
  descriptionFull: string;
  footnotes: CallingPlanFootnote[];
  destinations: CallingPlanDestination[];
}

const BrCallingPlanCard: React.FC<Props> = (props) => {
  const {
    id,
    planTypeText,
    title,
    descriptionShort,
    descriptionFull,
    actionText,
    actionType,
    bundledProduct,
    destinations,
    footnotes,
    functionalAddon,
    unavailableContent,
    expirationContent,
    isCombo,
    isNonPurchasable,
    recipientFormCfg,
    onActionBtnClick,
    onEditBtnClick,
  } = props;

  const { t } = useTranslation();

  return (
    <BrOfferCard
      id={id}
      planTypeText={planTypeText}
      title={title}
      descriptionShort={descriptionShort}
      actionText={actionText}
      bundledProduct={bundledProduct}
      unavailableContent={unavailableContent}
      expirationContent={expirationContent}
      functionalAddon={functionalAddon}
      isCombo={isCombo}
      actionType={actionType}
      isNonPurchasable={isNonPurchasable}
      recipientFormCfg={recipientFormCfg}
      onActionBtnClick={onActionBtnClick}
      onEditBtnClick={onEditBtnClick}
    >
      <div className="space-y-middle">
        <PlanExpandableInfoBlock
          title={t('Product Details')}
          iconName="product-fill"
          maxHeight={49}
          btnText={t('Read more')}
        >
          {descriptionFull}
        </PlanExpandableInfoBlock>
        {destinations.length ? (
          <PlanExpandableInfoBlock
            title={t('Available destinations')}
            iconName="location"
            maxHeight={80}
            btnText={t('Learn more')}
          >
            <DestinationsExpandableList
              destinations={destinations}
              footNotes={footnotes}
            />
          </PlanExpandableInfoBlock>
        ) : null}
      </div>
    </BrOfferCard>
  );
};

export default BrCallingPlanCard;
