import { makeAutoObservable } from 'mobx';

class Store {
  cvvCode?: string;

  isCvvRequired = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCvvCode = (cvvCode: string) => {
    this.cvvCode = cvvCode;
  };

  showCvvModal = () => {
    this.isCvvRequired = true;
  };

  hideCvvModal = () => {
    this.isCvvRequired = false;
  };
}

export default Store;
